<template>
  <v-card class="primary--text" style="overflow-y: hidden;">
    <core-header :shown="true" bgcolor="" :loading="false" column="x12">
    <template slot="content">
      <!-- TITLE -->
      <v-flex xs12 md8 class="px-2" style="margin-top:-14px!important;">
          <core-title color="primary" :title="header.title" />
      </v-flex>
      <!-- TOOLBAR -->
      <v-flex xs12 md4 class="px-2" style="margin-top:-22px !important; padding-right: 0px!important">
        <core-toolbar :btnsetting="false" :btnclose="true" :btnchecklist="false" :btnrefresh="false" :btnreset="false" v-on:closePage="detailClose"></core-toolbar>
      </v-flex>
    </template>
    </core-header>
    <core-alert :flagShow="body.alert.shown" v-on:funcflagShow="body.alert.shown=false" :txtType="body.alert.type" :txtMsgAlert="body.alert.msg" />
    <v-card-text style="padding:2px 2px 0px 10px!important;">
      <div class="form-detail-asset">
        <v-row>
          <v-flex xs12 md10 class="px-2">
            <h3>Detail Info</h3>
          </v-flex>
          <v-flex xs12 md2 class="px-2" v-if="body.form.sMandat=='N/A'||body.form.sPendamping=='N/A'||body.form.sKorlap=='N/A'">
            <div style="float:right; margin-top: 5px;">
              <v-btn small color="primary" @click="goToUsers"><v-icon>mdi-account-multiple-plus-outline</v-icon></v-btn>
            </div>
          </v-flex>
        </v-row>
        <v-divider></v-divider>
        <v-layout form style="padding:0px 10px 0px 0px!important;">
          <v-layout wrap align-center form col-line-one>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Kecamatan" v-model="body.form.kecName" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Kelurahan" v-model="body.form.kelName" dense/>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="TPS" v-model="body.form.tpsName" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Saksi Mandat" v-model="body.form.sMandat" dense/>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Saksi Pendamping" v-model="body.form.sPendamping" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 :disabled="modeEdit" :outlined="true" filled shaped type="text" label="Korlap" v-model="body.form.sKorlap" dense />
            </v-col>
            
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 type="text" :disabled="modeEdit" :outlined="true" filled shaped label="DPT" v-model="body.form.dpt" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 type="text" :disabled="!isAdmin" :outlined="true" label="Suara SAH" v-model="body.form.sah" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4">
              <core-entry-field-nested-label-dyn-type1 type="text" :disabled="!isAdmin" :outlined="true" label="Suara Tidak SAH" v-model="body.form.notsah" dense />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-4" v-for="(x) in body.form.paslon" :key="x.id" :disabled="!isAdmin">
              <core-entry-field-nested-label-dyn-type1 type="text" :outlined="true" :label="`Paslon #${x.nourut}`" v-model="x.iVoters" dense />
            </v-col>
          </v-layout>
        </v-layout>
      </div>
      <v-layout wrap align-center form col-line-one>
        <v-col cols="12" sm="6" style="margin-bottom: 0px !important;">
          <div class="form-detail-asset detail-asset">
            <v-row>
              <v-flex xs12 md10 class="px-2">
                <h3>Foto C-Hasil</h3>
              </v-flex>
              <v-flex xs12 md2 class="px-2" v-if="isAdmin">
                <div style="float:right; margin-top: 5px;">
                  <v-btn small color="primary" @click="changeImg(1)"><v-icon>mdi-camera-outline</v-icon></v-btn>
                  <input id="fileImage1" type="file" accept="image/*" ref="fileImage1" onclick="this.value=null;" @change="onImgChange1" style="cursor:pointer!important;"/>
                </div>
              </v-flex>
            </v-row>
            <v-divider></v-divider>
            <v-layout form>
              <v-layout wrap>
                <v-col cols="12" sm="12" md="12" wrap v-for="(item,i) in body.form.listImageM" :key="i">
                  <v-card class="mx-auto my-1">
                    <!-- <div class="col-image">
                      <h1 class="v-label v-label--is-disabled theme--light" >{{ item.name }}</h1>
                    </div> -->
                    <v-img v-if="item.pCH1==null" title="Show Image" style="cursor:pointer" @dblclick="popUpImage(item.error?noimage:item.src)" :src="item.error?noimage:item.src" @error="item.error=true" max-height="300" contain></v-img>
                    <v-img v-else title="Change Image" style="cursor:pointer" @dblclick="popUpImage(item.pCH1.src)" :src="item.pCH1.src" max-height="300" contain>
                      <div style="float:right">
                        <v-btn right fab><v-icon color="primary" title="Remove Change Image" @click="body.form.listImageM[0].pCH1=null">mdi-delete</v-icon></v-btn>
                      </div>
                    </v-img>
                  </v-card>
                </v-col>
              </v-layout>
            </v-layout>
          </div>
        </v-col>
        <v-col cols="12" sm="6" style="margin-bottom: 0px !important;">
          <div class="form-detail-asset detail-asset">
            <v-row>
              <v-flex xs12 md10 class="px-2">
                <h3>Foto C-Plano</h3>
              </v-flex>
              <v-flex xs12 md2 class="px-2" v-if="isAdmin">
                <div style="float:right; margin-top: 5px;">
                  <v-btn small color="primary" @click="changeImg(2)"><v-icon>mdi-camera-outline</v-icon></v-btn>
                  <input id="fileImage2" type="file" accept="image/*" ref="fileImage2" onclick="this.value=null;" @change="onImgChange2" style="cursor:pointer!important;"/>
                </div>
              </v-flex>
            </v-row>
          <v-divider></v-divider>
          <v-layout form>
            <v-layout wrap>
              <v-col cols="12" sm="12" md="12" wrap v-for="(item,i) in body.form.listImageN" :key="i">
                <v-card class="mx-auto my-1">
                  <!-- <div class="col-image">
                    <h1 class="v-label v-label--is-disabled theme--light" >{{ item.name }}</h1>
                  </div> -->
                  <v-img v-if="item.pCH2==null" title="Show Image" style="cursor:pointer" @dblclick="popUpImage(item.error?noimage:item.src)" :src="item.error?noimage:item.src" @error="item.error=true" max-height="300" contain></v-img>
                  <v-img v-else title="Change Image" style="cursor:pointer" @dblclick="popUpImage(item.pCH2.src)" :src="item.pCH2.src" max-height="300" contain>
                    <div style="float:right">
                      <v-btn right fab><v-icon color="primary" title="Remove Change Image" @click="body.form.listImageN[0].pCH2=null">mdi-delete</v-icon></v-btn>
                    </div>
                  </v-img>
                </v-card>
              </v-col>
            </v-layout>
          </v-layout>
        </div>
        </v-col>
      </v-layout>
      <v-card-actions class="footer-button-asset" v-if="isAdmin">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" small @click="detailClose">Cancel</v-btn>
        <v-btn class="btn-submit" small @click="btnSubmit" :disabled="body.form.loading">Submit</v-btn>
      </v-card-actions>
    </v-card-text>

    <!-- Popup Image -->
    <v-dialog v-model="showImage" width="1200">
      <v-card>
        <v-img :src="changeImage" @error="item.error=true" contain></v-img>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
// FUNCTION
import {success, error} from '@/consistencychecker/transactionvalidator/index'
import {showString} from '@/enums/index'
import {numberFormat} from '@/consistencychecker/formvalidator/format'
import {isValidForm} from '@/consistencychecker/formvalidator/index'
import VotingAPI from "@/services/sa/SA_Voting";

export default {
  metaInfo () { return { title: 'Quick Count' } },
  name: 'DetailTPS',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    modeEdit: {
      type: Boolean,
      default: true
    },
    data:{
      type: Object,
      default: {
        tpsID:0, kecID:0, kelID:0, iMandat:0, sMandat:``, iPendamping:0, sPendamping:``,
        kecName:'', kelName:'', tpsName:'', iKorlap:0, sKorlap:``,
        notsah:0, sah:0, dpt:0, dptb:0, paslon:[],
      }
    }
  },
  beforeDestroy(){
    this.changeImage=''  
  },
  data (){
    return {
      header:{
        title:"Laporan Perolehan Suara",
      },
      showImage: false, changeImage:'', noimage: `${process.env.VUE_APP_BASE_URL}/noimage.png`,
      isAdmin:['ADMIN','Admin Data'].includes(this.$store.getters["auth/getSession"].sRole),
      body: {
        form:{
          kecID:showString(this.data.kecID), kelID:showString(this.data.kelID), tpsID:showString(this.data.tpsID),
          kecName:showString(this.data.kecName), kelName:showString(this.data.kelName), tpsName:showString(this.data.tpsName), 
          dpt:`${this.data.dptb>0? numberFormat(this.data.dpt)+' (+'+numberFormat(this.data.dptb)+')':numberFormat(this.data.dpt)}`, 
          sah:numberFormat(this.data.sah), notsah:numberFormat(this.data.notsah),
          iMandat:showString(this.data.iMandat),sMandat:showString(this.data.sMandat),
          iPendamping:showString(this.data.iPendamping),sPendamping:showString(this.data.sPendamping),
          iKorlap:showString(this.data.iKorlap),sKorlap:showString(this.data.sKorlap),
          paslon:(this.data?this.data.paslon:[]),
          listImageM:(this.data?this.data.listImage:[]),
          listImageN:(this.data?this.data.listImage:[])
        },
        alert:{
          shown: false,
          type: 'success',
          msg: ''
        }
      },
      footer: {
      },
    }
  },
  beforeMount(){
    this.setDataImage()
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    setDataImage(){
      let urlImage = `${process.env.VUE_APP_END_GW}/voting/`
      let listImageM=[{id:1, name:`C-Hasil`, cat:1, src:`${urlImage}${this.body.form.tpsID}?ph=1`, error:false, pCH1:null} ] 
      let listImageN=[{id:2, name:`C-Plano`, cat:0, src:`${urlImage}${this.body.form.tpsID}?ph=2`, error:false, pCH2:null} ]
      this.body.form.listImageM = [...listImageM]
      this.body.form.listImageN = [...listImageN]
    },
    goToUsers(){
      window.location.href = window.location.origin + process.env.VUE_APP_BASE_URL + "/users"
    },
    detailClose(){
      this.$emit('closePopUp');
    },
    btnSubmit(){
      let totalSuara = parseInt(this.body.form.sah) + parseInt(this.body.form.notsah)
      let validDataCheck = {
        iVotingPlaceID: this.body.form.tpsID,
        kecID: this.body.form.kecID,
        kelID: this.body.form.kelID,
        iCountNotLegitimate: this.body.form.notsah,
        iCountLegitimate: this.body.form.sah,
        iDPT: totalSuara,
        dataPaslon: JSON.stringify(this.body.form.paslon),
        sBy: this.$store.getters["auth/getSession"].iUserID
      }
      let validEntry = isValidForm(validDataCheck)
      if(validEntry.status===false) {
        this.handleError(`Ditolak. Harap melangkapi data *Required!`)
      }else{
        let files=[]
        if(this.body.form.listImageM[0].pCH1!=null){
          files.push({id:'pCH1',name:this.body.form.listImageM[0].pCH1.filename,file:this.body.form.listImageM[0].pCH1.files})
        }
        if(this.body.form.listImageN[0].pCH2!=null){
          files.push({id:'pCH2',name:this.body.form.listImageN[0].pCH2.filename,file:this.body.form.listImageN[0].pCH2.files})
        }
        VotingAPI.updateVoting(validDataCheck, this.body.form.tpsID, files).then(data=>{ 
          this.handleSuccess(data)
          setTimeout(()=>{this.detailClose();},2000)
        }).catch(err=> {
          this.handleError(err)
        })
      }
    },
    handleSuccess(data){
      this.body.alert.shown = true
      this.body.alert.type = this.$store.getters['app/color']('s')
      this.body.alert.msg = success(data)
      setTimeout(()=>{this.body.alert.shown=false; this.show=false},3000)
    },
    handleError(err){
      this.body.alert.shown = true
      this.body.alert.type = this.$store.getters['app/color']('e')
      this.body.alert.msg = error(err)
      setTimeout(()=>{this.body.alert.shown=false},3000)
    },
    popUpImage(src){
      this.changeImage=src
      this.showImage=true
    },
    changeImg(r){
      this.$refs[`fileImage${r}`].click()
    },
    onImgChange1($event){
      let pCH1 = {name:null, src:``, fullPath:``, files:null}
      let files = $event.target.files || $event.dataTransfer.files
      if (files) {
        if(files.length > 0){
          pCH1.name = [...files].map(file => file.name).join(', ')
        }else{
          pCH1.filename = null
        }
      } else {
        pCH1.filename = $event.target.value.split('\\').pop()
      }
      pCH1.src = URL.createObjectURL(files[0])
      pCH1.fullPath = this.$refs.fileImage1.value
      pCH1.files = files[0]
      this.body.form.listImageM[0].pCH1 = pCH1
    },
    onImgChange2($event){
      let pCH2 = {name:null, src:``, fullPath:``, files:null}
      let files = $event.target.files || $event.dataTransfer.files
      if (files) {
        if(files.length > 0){
          pCH2.name = [...files].map(file => file.name).join(', ')
        }else{
          pCH2.filename = null
        }
      } else {
        pCH2.filename = $event.target.value.split('\\').pop()
      }
      pCH2.src = URL.createObjectURL(files[0])
      pCH2.fullPath = this.$refs.fileImage2.value
      pCH2.files = files[0]
      this.body.form.listImageN[0].pCH2 = pCH2
    },
  }
}
</script>
<style>
  .form-detail-asset h3 {
    font-size: 18px !important;
  }
  .status-request {
    background-color: #FFB727 !important;
    border-color: #FFB727 !important;
  }

  .detail-asset .v-image{
    max-height: 300px;
    cursor: pointer;
    height: 200px;
    width: 520px;
    margin: auto;
    border: 1px solid #dddddd;
    border-radius: 10px !important;
  }
  
  .detail-asset .v-image__image--contain{
    background-size: cover;
  }

  .form-detail-asset .form {
    padding: 8px 0 0 8px;
  }
</style>